import {
  TextareaHTMLAttributes,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import styled from "styled-components"

import { useResizeObserver } from "usehooks-ts"

import {
  InputContainer,
  TInputContainerProps,
} from "src/ui/InputContainer/InputContainer"

type THTMLTextAreaAttributes = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  "onChange"
>

export type TMTextareaProps = THTMLTextAreaAttributes & {
  value: string
  placeholder?: string
  ariaLabel?: string
  onChange: (value: string) => void
  disabled?: boolean
  minRows?: number
  maxRows?: number
} & TInputContainerProps

export function MTextArea({
  value,
  label,
  placeholder,
  ariaLabel,
  onChange,
  onClear,
  error,
  helperText,
  disabled,
  startAdornment,
  endAdornment,
  requiredIndicator,
  minRows = 1,
  maxRows = 10,
  ...props
}: TMTextareaProps) {
  const [height, setHeight] = useState<number | null>(null)

  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  // This element is used to do the height calculations
  const shadowTextAreaRef = useRef<HTMLTextAreaElement>(null)

  const normalizedMinRows = Math.max(1, minRows)
  const normalizedMaxRows = Math.max(1, maxRows)

  const calculateTextAreaHeight = useCallback(() => {
    if (shadowTextAreaRef.current) {
      // Get current text height by inserting the value in the shadow textarea
      shadowTextAreaRef.current.value = value
      const currentHeight = shadowTextAreaRef.current.scrollHeight

      // Reset the value of the shadow textarea to get the height of a single line
      shadowTextAreaRef.current.value = ""
      const singleLineHeight = shadowTextAreaRef.current.scrollHeight

      const minHeight = normalizedMinRows * singleLineHeight
      const maxHeight = normalizedMaxRows * singleLineHeight

      const newHeight = Math.min(Math.max(currentHeight, minHeight), maxHeight)

      setHeight(Math.min(newHeight, maxHeight))
    }
  }, [normalizedMaxRows, normalizedMinRows, value])

  useResizeObserver({
    ref: textAreaRef,
    onResize: calculateTextAreaHeight,
  })
  useLayoutEffect(() => {
    calculateTextAreaHeight()
  }, [value, calculateTextAreaHeight])

  return (
    <InputContainer
      label={label}
      error={error}
      showClearButton={!!onClear && !!value}
      onClear={onClear}
      helperText={helperText}
      disabled={disabled}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      requiredIndicator={requiredIndicator}
      cursor="text"
    >
      <Wrapper>
        <StyledTextarea
          ref={textAreaRef}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          rows={1}
          style={{
            height: height ?? undefined,
          }}
          {...props}
        />
        <VisuallyHiddenTextarea
          ref={shadowTextAreaRef}
          tabIndex={-1}
          readOnly
          aria-hidden
        />
      </Wrapper>
    </InputContainer>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const StyledTextarea = styled.textarea`
  display: block;
  font: inherit;
  color: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  resize: none;
`

const VisuallyHiddenTextarea = styled(StyledTextarea)`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  visibility: hidden;
  padding: 0;
`
